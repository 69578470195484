.container {
  display: block;
}

.link {
  text-align: center;
  a {
    color: hsla(80,30%,50%,1);
    text-decoration: none;
    display: inline-block;
    border: 1px solid hsla(80, 10%, 50%, 0.4);
    padding: 20px;
    width: 50vw;
    margin-bottom: 20px;
  }
}