import { createRoot } from 'react-dom/client';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import data from '../links.yml';
import { container, link } from './app.module.scss';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

function HomePage () {
  return (
    <div className={container}>
      {data.links.map(
        ({href, title}) => (
          <div className={link}><a href={href}>{title}</a></div>
        )
      )}
    </div>
  )
}


root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
);